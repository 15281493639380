<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-2)">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">培训详情</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">上课学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="Name" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="idCard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="phone" type="text" size="small" placeholder="请输入手机号" clearable />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select v-model="studystate" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in studylist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right:1rem">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
              <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idcard" />
              <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
              <el-table-column
                label="课件总数"
                align="center"
                show-overflow-tooltip
                prop="totalLessonNum"
              />
              <el-table-column
                label="已完成课件数量"
                align="center"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column
                label="已完成必修课件数量"
                align="center"
                show-overflow-tooltip
                prop="studyLessonNumMust"
              />
              <el-table-column label="已完成选修课件" align="center" show-overflow-tooltip prop>
                <template
                  slot-scope="scope"
                >{{(scope.row.studyLessonNum-scope.row.studyLessonNumMust).toFixed(1)}}</template>
              </el-table-column>
              <el-table-column label="学习状态" align="center" prop="studyState">
                <template slot-scope="scope">
                  <span v-if="scope.row.studyState!='40'">{{$setDictionary("LEARNINGSTATE", scope.row.studyState)}}</span>
                   <el-tooltip v-else
                    class="item"
                    effect="dark"
                    :content="scope.row.notCompleteReasons"
                    placement="top"
                  >
                    <span style="cursor: pointer; display: flex;justify-content: center;">{{ $setDictionary("LEARNINGSTATE", scope.row.studyState)}}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>


<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "StudentsInClass",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      Name: "",
      idCard: "",
      phone: "",
      studystate: "",
      studylist: [],
      projectId: "",
      projectCourseId: ""
    };
  },
  created() {
    this.getCrowdtypeList();
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype"
    })
  },
  mounted() {},
  methods: {
    getCrowdtypeList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key]
        });
      }
      this.studylist = list;
    },
    getData(pageNum = 1) {
      this.projectId = sessionStorage.getItem("projectId");
      this.projectCourseId = sessionStorage.getItem("projectCourseId");
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        projectCourseId: this.projectCourseId
      };
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.phone) {
        params.mobile = this.phone;
      }
      if (this.Name) {
        params.userName = this.Name;
      }
      if (this.studystate) {
        params.completeState = this.studystate;
      }
      this.doFetch({
        url: "/biz/projectUser/studyListPage",
        params,
        pageNum
      });
    },
    // getData(pageNum = 1) {
    //   const params = {
    //     pageNum: pageNum,
    //     pageSize: this.pageSize,
    //     projectName: this.projectName || "",
    //   };
    //   this.doFetch({
    //     url: "/project/listPage",
    //     params,
    //     pageNum,
    //   });
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
